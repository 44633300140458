/**
 * Returns if a DOM element has a specified class or not
 */
function hasClass (elem, cl) {
    return elem.classList.contains(cl)
}

/**
 * Adds the specified class to the given DOM element(s)
 */
function addClass (selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector

        if (!hasClass(elem, cl)) {
            elem.classList.add(cl)
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector)

        if (elems.length) {
            for (let elem of elems) {
                if (!hasClass(elem, cl)) {
                    elem.classList.add(cl)
                }
            }
        }
    }

    return null
}

/**
 * Removes the specified class from the given DOM element(s)
 */
function removeClass (selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector

        if (hasClass(elem, cl)) {
            elem.classList.remove(cl)
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector)

        if (elems.length) {
            for (let elem of elems) {
                if (hasClass(elem, cl)) {
                    elem.classList.remove(cl)
                }
            }
        }
    }

    return null
}

/**
 * Adds or removes the specified class to/from the given DOM element(s)
 */
function toggleClass (selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector

        if (hasClass(elem, cl)) {
            elem.classList.remove(cl)
        } else {
            elem.classList.add(cl)
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector)

        if (elems.length) {
            for (let elem of elems) {
                if (hasClass(elem, cl)) {
                    elem.classList.remove(cl)
                } else {
                    elem.classList.add(cl)
                }
            }
        }
    }
}
